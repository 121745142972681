@use '../../utils/variables' as variables;

#contact {

    padding-top: 5rem;
    padding-bottom: 5rem;

    .contact__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 14%;

        // TABLET
        @media (max-width: variables.$md) { 
            gap: 10%;
        }

        // MOBILE
        @media (max-width: variables.$sm) { 
            flex-direction: column;
            gap: 4rem;
        }

        .joinus {
            text-align: left;
            width: 43%;

            // MOBILE
            @media (max-width: variables.$sm) { 
                width: 100%;
            }
        }

        .contactform__container {
            width: 43%;
            padding: 1.2rem 2rem;
            display: flex;
            flex-direction: column;
            text-align: left;
            background: #FFFFFF;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.13);
            border-radius: 20px;

            // TABLET
            @media (max-width: variables.$md) { 
                width: 47%;
            }

            // MOBILE
            @media (max-width: variables.$sm) { 
                width: 100%;
            }

            p {
                font-weight: 400;
                margin-bottom: 7px;
            }

            textarea {
                resize: none;
            }

            input, textarea {
                border: 0.5px solid rgba(0, 0, 0, 0.23);
                border-radius: 10px;
                padding: 14px 20px;
                font-size: 14px;
            
                // TABLET
                @media (max-width: variables.$md) { 
                    padding: 9px 12px;
                }

                // MOBILE
                @media (max-width: variables.$sm) { 
                    padding: 9px 12px;
                }
            }

            button {
                padding: 0.6rem;
            }

        }


        .loader {
            margin-left: auto;
            margin-right: auto;
            font-size: 35px;
            color: var(--color-primary);
            animation: spin 2s linear infinite;
            width: fit-content;
            display: flex;
            align-items: center;
        }
        
          
        @-webkit-keyframes spin {
            0% {
                -webkit-transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(359deg);
            }
        }
        
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(359deg);
            }
        }

    }
}