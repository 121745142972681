@use '../../utils/variables' as variables;

#header {
    background-color: var(--color-secondary);
    .gradient {
        // background: url('../../assets/header_background.svg') no-repeat center center; 
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        // background-image:url('../../assets/header_background.svg');
        // background-size: cover;
        width: 100%;
        height: 92vh;

         // TABLET
         @media (max-width: variables.$md) { 
            height: 60vh;
        }
    }

    .container {
        height: 100%;
    }

    .header__content {

        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: auto;
        text-align: left;

        h5 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 131.3%;
            letter-spacing: 0.06em;
            text-transform: uppercase;
            color: var(--color-primary);
            margin-bottom: 1rem;

            // TABLET
            @media (max-width: variables.$md) { 
                font-size: 16.2581px;
            }

            // MOBILE
            @media (max-width: variables.$sm) { 
                font-size: 16.2581px;
            }
        }

        h1 {
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 127.26%;/* or 64px */
            letter-spacing: 0.06em;
            text-transform: uppercase;
            color: #FFFFFF;

            // TABLET
            @media (max-width: variables.$md) { 
                font-size: 33.871px;
                line-height: 127.26%;
            }

            // MOBILE
            @media (max-width: variables.$sm) { 
                font-size: 33.871px;
                line-height: 127.26%;
            }
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 35px;/* or 194% */
            color: #FFFFFF;
            width: 45%;
            margin-top: 1rem;

            // TABLET
            @media (max-width: variables.$md) { 
                font-size: 12.1935px;
                line-height: 24px;
                width: 50%;
            }

            // MOBILE
            @media (max-width: variables.$sm) { 
                font-size: 16.19px;
                line-height: 24px;
                width: 90%;
            }
        }

        button {
            padding: 7px 54px;
            border-radius: 3px;
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            margin-top: 1.5rem;

            // TABLET
            @media (max-width: variables.$md) { 
                font-size: 10.8387px;
                line-height: 19px;
            }

            // MOBILE
            @media (max-width: variables.$sm) { 
                font-size: 10.8387px;
                line-height: 19px;
            }
        }
    }
}