@use './utils/variables' as variables;

@import url('https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;500;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {    
    
    --color-bg: rgba(2, 152, 153, 0.07);
    --color-bg-dimgray: rgba(29, 52, 99, 0.04);
    --color-primary: #71C8B2;
    --color-primary-dark: #579c8b;
    --color-secondary: #1D3463;
    --color-secondary-dark: #15274b;
    --color-gray-80: #808080;
    --color-gray-e4: #e4e4e6;
    --color-gray-4e: #4E4E4E;
    --color-gray-f9: #F9F9FB;
    
    --color-error: #F57E77;

    --color-txt-secondary: rgba(0, 0, 0, 0.54);

    --transition: all 500ms ease;
    --transition-1: all 1000ms ease;
    --transition-2: all 2000ms ease;
    --transition-slow: all 1000ms ease;

    --container-width-lg: 85%;
    --container-width-md: 90%;
    --container-width-sm: 90%;

    --font-primary: 'Baloo Paaji 2', sans-serif;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    // display: none;
}

body {
    font-family: var(--font-primary), sans-serif;
}




/* GENERAL STYLES */

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 7rem;

    // TABLET
    @media (max-width: variables.$md) { 
        margin-top: 4rem;
    }

    // MOBILE
    @media (max-width: variables.$sm) { 
        margin-top: 2rem;
    }
}

a {
    color: var(--color-txt-secondary);
    transition: var(--transition)
}

a:hover {
    color: var(--color-primary);
}

.btn {
    width: max-content;
    height: min-content;
    display: inline-block;
    background-color: var(--color-primary);
    color: white;
    border-radius: 9px;
    cursor: pointer;
    transition: var(--transition);

    font-style: normal;
    font-weight: 500;
    font-size: 15px;
}

.btn:hover {
    background-color: var(color-primary-dark);
    transform: translate(0, -3px) !important;
}

.section__heading {
    font-family: 'Gilroy';
    font-style: normal;
    font-size: 32px;
    line-height: 83px;
    color: var(--color-secondary);

    // TABLET
    @media (max-width: variables.$md) { 
        font-size: 20px;
        line-height: 83px;
    }

    // MOBILE
    @media (max-width: variables.$sm) { 
        font-size: 32px;
        line-height: 83px;
    }
}

.section__subheading {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    text-transform: capitalize;
    color: var(--color-primary);
}

// .btn-primary {
//     background: var(--color-primary);
//     color: var(--color-bg);
// }

// img {
//     display: block;
//     width: 100%;
//     object-fit: cover;
// }


/* MEDIA QUERIES (MEDIUM DEVICES) */

@media (max-width: variables.$lg) {

    .container {
        width: var(--container-width-md);
    }    
}



/* MEDIA QUERIES (SMALL DEVICES) */

@media (max-width: variables.$sm) {

    .container {
        width: var(--container-width-sm);
    }
}


// HELPER CLASSES
.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.ml-auto {
    margin-left: auto;
}
.mr-auto {
    margin-right: auto;
}

.mt-0 {
    margin-top: 0 !important;
}
.mt-1 {
    margin-top: 1rem !important;
}
.mt-2 {
    margin-top: 2rem;
}

.v-hidden {
    visibility: hidden;
}

.text-color-primary {
    color: var(--color-primary);
}

.hide-ontablet {
    // TABLET
    @media (max-width: variables.$md) { 
        display: none !important;
    }
}

.show-ontablet {
    display: none;
    // TABLET
    @media (max-width: variables.$md) { 
        display: block;
    }
}


.hide-onmobile {
    // MOBILE
    @media (max-width: variables.$sm) { 
        display: none !important;
    }
}

.show-onmobile {
    display: none;
    // MOBILE
    @media (max-width: variables.$sm) { 
        display: block;
    }
}

.heading-20 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    text-transform: uppercase;
    color: var(--color-primary);
}

.heading-24 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 131.3%;/* or 32px */
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: var(--color-primary);
}

.heading-25 {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 131.3%;/* identical to box height, or 33px */
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: var(--color-primary);

    // TABLET
    @media (max-width: variables.$md) { 
        font-size: 13.3836px;
        line-height: 131.3%;
    }

    // MOBILE
    @media (max-width: variables.$sm) { 
        font-size: 23.05px;
        line-height: 131.3%;
    }
}

.heading-28 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 131.3%;/* identical to box height, or 37px */
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: var(--color-primary);

    // TABLET
    @media (max-width: variables.$md) { 
        font-size: 15.0494px;
        line-height: 131.3%;
    }

    // MOBILE
    @media (max-width: variables.$sm) { 
        font-size: 23.05px;
        line-height: 131.3%;
    }
}

.heading-35 {
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 131.3%;/* identical to box height, or 46px */
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: var(--color-primary);

    // TABLET
    @media (max-width: variables.$md) { 
        font-size: 18.1076px;
        line-height: 131.3%;
    }

    // MOBILE
    @media (max-width: variables.$sm) { 
        font-size: 23.05px;
        line-height: 131.3%;
    }
}

.p-13 {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 133%;/* or 17px */
    color: #FFFFFF;

    // TABLET
    @media (max-width: variables.$md) { 
        font-size: 6.74375px;
        line-height: 11px;
    }

    // MOBILE
    @media (max-width: variables.$sm) { 
        font-size: 13px;
        line-height: 22px;
    }
}

.p-17 {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #FFFFFF;

    // TABLET
    @media (max-width: variables.$md) { 
        font-size: 9.06638px;
        line-height: 13px;
    }

    // MOBILE
    @media (max-width: variables.$sm) { 
        font-size: 16px;
        line-height: 26px;
    }
}

.p-18 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;/* or 167% */
    color: #FFFFFF;

    // TABLET
    @media (max-width: variables.$md) { 
        font-size: 9.67464px;
        line-height: 16px;
    }

    // MOBILE
    @media (max-width: variables.$sm) { 
        font-size: 16px;
        line-height: 24px;
    }
}

.p-20 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;/* or 125% */
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;

    // TABLET
    @media (max-width: variables.$md) { 
        font-size: 10.6663px;
        line-height: 13px;
    }

    // MOBILE
    @media (max-width: variables.$sm) { 
        font-size: 18px;
        line-height: 28px;
    }
}

.p-21 {
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 139.3%;/* or 29px */
    letter-spacing: 0.06em;
    color: #FFFFFF;

    // TABLET
    @media (max-width: variables.$md) { 
        font-size: 10.8938px;
        line-height: 139.3%;
    }

    // MOBILE
    @media (max-width: variables.$sm) { 
        font-size: 16px;
        line-height: 139.3%;
    }
}

.p-22 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;/* or 136% */
    text-align: center;
    color: #FFFFFF;

    // TABLET
    @media (max-width: variables.$md) { 
        font-size: 11.3819px;
        line-height: 16px;
    }

    // MOBILE
    @media (max-width: variables.$sm) { 
        font-size: 16px;
        line-height: 24px;
    }
}

.color-gray-80 {
    color: var(--color-gray-80) !important;
}


.mb-h {
    margin-bottom: 0.5rem;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}
.mb-3 {
    margin-bottom: 3rem;
}

.mb-4 {
    margin-bottom: 4rem;
}
.mb-5 {
    margin-bottom: 5rem;
}

.pb-1 {
    padding-bottom: 1rem;
}

.pb-2 {
    padding-bottom: 2rem;
}

.w-70 {
    width: 70%;
}
.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.w-100 {
    width: 100%;
}


// PLACE HOLDER
::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: var(--font-primary), sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #00000052;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: var(--font-primary), sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #00000052;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    font-family: var(--font-primary), sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #00000052;
  }


.font-weight-600 {
    font-weight: 600 !important;
}

.background__secondary {
    background-color: var(--color-secondary);
}

.active-nav-link {
    color: var(--color-primary) !important;
}

.error-msg {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--color-error);
    text-align: start;
}

.success-msg {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--color-primary-dark);
    text-align: start;
}
