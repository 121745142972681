@use '../../utils/variables' as variables;

#business-park {
    background-color: var(--color-bg-dimgray);
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    .businesspark__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 13%;

        // MOBILE
        @media (max-width: variables.$sm) { 
            flex-direction: column;
        }

        .bpark__img{
            width: 30%;
            min-width: 30%;

            // MOBILE
            @media (max-width: variables.$sm) { 
                width: 100%;
                min-width: 100%;
                margin-bottom: 2.5rem;
            }
        }

        .businesspark__details {
            .businesspark__subcontainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 2rem;

                // MOBILE
                @media (max-width: variables.$sm) { 
                    flex-direction: column;
                }

                img {
                    width: 30%;
                    min-width: 30%;

                    // MOBILE
                    @media (max-width: variables.$sm) { 
                        width: 100%;
                        min-width: 100%;
                    }
                }

                p {
                    word-spacing: 0.9rem;
                    line-height: 25px;/* or 167% */

                    // TABLET
                    @media (max-width: variables.$md) { 
                        line-height: 15px;
                    }

                    // MOBILE
                    @media (max-width: variables.$sm) { 
                        width: 100%;
                        font-size: 16px;
                        line-height: 24px;
                        word-spacing: 0rem;
                    }
                }
            }
        }
    }
}