@use '../../utils/variables' as variables;


#capitalsmartcity {
    padding-top: 5rem;

    // MOBILE
    @media (max-width: variables.$sm) {
        padding-top: 3rem;
    }

    .child__infa {
        // MOBILE
        @media (max-width: variables.$sm) { 
            // to nullify the margin that is being set from child
            margin-top: -3rem;
        }
    }
}