@use '../../utils/variables' as variables;

.textLeftImgRight__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15%;
    padding-top: 5rem;
    padding-bottom: 5rem;

    // TABLET
    @media (max-width: variables.$md) { 
        
    }

    // MOBILE
    @media (max-width: variables.$sm) { 
        padding-top: 3rem;
        padding-bottom: 3rem;
        flex-direction: column;
        flex-direction: column-reverse;
    }

    .heading {
        margin-bottom: 2rem;
        
        // MOBILE
        @media (max-width: variables.$sm) { 
            margin-top: 2rem;
            margin-bottom: 0.7rem;
        }
    }

    img {
        width: 27%;
        // MOBILE
        @media (max-width: variables.$sm) { 
            width: 100%;
        }
    }
}