@use '../../utils/variables' as variables;


#aboutus {
    // background-color: var(--color-secondary);

    .aboutus__container {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 20%;
        padding-top: 5rem;
        padding-bottom: 5rem;

        // TABLET
        @media (max-width: variables.$md) { 
            padding-bottom: 10rem;
        }

        // MOBILE
        @media (max-width: variables.$sm) { 
            flex-direction: column-reverse;
        }

        .heading-28 {
            margin-bottom: 1.2rem;
        }

        p {
            // MOBILE
            @media (max-width: variables.$sm) { 
                width: 100%;
            }
        }

        img {
            width: 30%;
            min-width: 30%;

            // TABLET
            @media (max-width: variables.$md) { 
                width: 35%;
                min-width: 35%;
            }

            // MOBILE
            @media (max-width: variables.$sm) { 
                width: 100%;
                min-width: 100%;
                margin-bottom: 3rem;
            }
        }
    }

    .aboutus__txtcontainer {
        text-align: left;
    }
}