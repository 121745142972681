@use '../../utils/variables' as variables;

#molecule {
    background-color: var(--color-secondary);
    padding-top: 4rem;
    padding-bottom: 5rem;

    .molecule__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .molecule__logo {
            width: 30%;
            min-width: 30%;
            margin-top: 1rem;
            margin-bottom: 3rem;

            // MOBILE
            @media (max-width: variables.$sm) { 
                width: 50%;
                min-width: 50%;
            }
        }

        .molecule__description {
            width: 80%;

            // MOBILE
            @media (max-width: variables.$sm) { 
                width: 100%;
            }
        }

        .molecule__weblogo {
            width: 100%;
            min-width: 100%;
        }

        .molecule__subcontainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15%;

            // MOBILE
            @media (max-width: variables.$sm) { 
                flex-direction: column-reverse;
                gap: 3rem;
            }

            ul {
                li {
                    // list-style-type: disc;
                    // list-style-position: inside;
                    margin-bottom: 0.5rem;
                }

                li:before {
                    content:"·";
                    font-size:30px;
                    vertical-align:middle;
                    line-height:20px;
                    margin-right: 7px;
                }
            }

            .childmolecule__logo {
                width: 35%;
                min-width: 35%;
                // TABLET
                @media (max-width: variables.$md) { 
                    
                }

                // MOBILE
                @media (max-width: variables.$sm) { 
                    min-width: 75%;
                }
            }
        }
    }
}

.find-more-btn {
    padding: 10px 50px;
    border: 1px solid #FFFFFF;
    border-radius: 3px;
    background-color: transparent;

    font-family: 'Baloo Paaji 2';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    text-align: center;

    color: #FFFFFF;
    cursor: pointer
}