@use '../../utils/variables' as variables;

#footer {
    background-color: var(--color-secondary);
    padding-top: 5rem;

    .footer__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 7%;
        text-align: left;

        .footer__description {
            width: 70%;
        }

        // TABLET
        @media (max-width: variables.$md) { 

        }

        // MOBILE
        @media (max-width: variables.$sm) { 
            grid-template-columns: 1fr;
            gap: 4rem;

            .footer__description {
                width: 100%;
            }

            // :nth-child(1) {
            //     grid-column: span 2;
            // }

            // :nth-child(4) {
            //     grid-column: span 2;
            // }            
        }

        .contact__icons {
            width: 15px;
            height: 15px;
            color: var(--color-primary);
            margin-right: 7px;
            vertical-align:middle;
            cursor: pointer;
        }

        .footer__col {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            a {
                cursor: pointer;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                overflow: hidden;

                // TABLET
                @media (max-width: variables.$md) { 
                    
                }

                // MOBILE
                @media (max-width: variables.$sm) { 
                    
                }
            }
        }

        .brand__img {
            width: 65%;
            margin-left: auto;
            margin-right: auto;

            // MOBILE
            @media (max-width: variables.$sm) { 
                width: 50%;
            }
        }
    }

    .footer__border {
        border-top: 0.4px solid var(--color-primary);
        margin-top: 3rem;
        width: 100%;
        height: 0px;
    }

    .footer__copyright {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;

        // MOBILE
        @media (max-width: variables.$sm) { 
                    
        }
    }

    .footer__copyright-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 2rem;
        a {
            cursor: pointer;
        }
    }
}


.social__icons {
    img{
        height: 40px;
        width: 40px;
    }
    a {
        display: flex;
        color: var(--color-primary);
    }
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
}