@use '../../utils/variables' as variables;

#brochuredownload {
    background-color: #fff;
    padding-top: 4rem;
    padding-bottom: 4rem;

    .brochure__section {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;

        .desc {
            width: 70%;

            // TABLET
            @media (max-width: variables.$md) { 
                width: 80%;
            }

            // MOBILE
            @media (max-width: variables.$sm) { 
                width: 90%;
            }

        }

        .buttons__section {
            display: flex;
            flex-direction: row;
            gap: 2rem;

            // TABLET
            @media (max-width: variables.$md) { 
                
            }

            // MOBILE
            @media (max-width: variables.$sm) { 
                flex-direction: column;
            }

            button {
                padding: 10px;
                width: 200px;
                
                font-family: 'Baloo Paaji 2';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 139.3%;
                /* identical to box height, or 25px */

                text-align: center;
                letter-spacing: 0.06em;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;

                // MOBILE
                @media (max-width: variables.$sm) { 
                    font-size: 14px;
                    line-height: 139.3%;
                }
            }

            .view__btn {
                display: flex;
                flex-direction: row;
                gap: 0.5rem;
            }

            .download__btn {
                background-color: #fff;
                color: var(--color-primary);
                border: 1px solid var(--color-primary);

                display: flex;
                flex-direction: row;
                gap: 0.5rem;
            }
        }
    }
}