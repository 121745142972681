@use '../../utils/variables' as variables;

.img__middle {
    background-color: var(--color-bg-dimgray);
    padding-top: 4rem;
    padding-bottom: 4rem;

    // TABLET
    @media (max-width: variables.$md) { 
        
    }

    // MOBILE
    @media (max-width: variables.$sm) { 
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .imgmiddle__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5%;
        margin-top: 1.5rem;

        // TABLET
        @media (max-width: variables.$md) { 
            
        }

        // MOBILE
        @media (max-width: variables.$sm) { 
            flex-direction: column;
        }

        p {
            width: 30%;

            // MOBILE
            @media (max-width: variables.$sm) { 
                width: 100%;
                margin-bottom: 1rem;
            }
        }
        img {
            width: 30%;
            min-width: 30%;

            // MOBILE
            @media (max-width: variables.$sm) { 
                width: 100%;
                min-width: 100%;
                margin-bottom: 1rem;
            }
        }
    }
}