@use '../../utils/variables' as variables;

.multiplevision__container {

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    transform: translateY(-45%);
    position: relative;
    z-index: 0;
    

    // TABLET
    @media (max-width: variables.$md) { 
    }

    // MOBILE
    @media (max-width: variables.$sm) { 
        grid-template-columns: 1fr;
        transform: translateY(-8%);
    }

    .vision {
        cursor: pointer;

        img {
            width: 100%;
        }

        .vision__container {
            position: relative;

        }
        .vision__details {
            background: linear-gradient(180deg, #71C8B2 0%, #1D3463 100%);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: var(--transition-1);
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                opacity: 1;
                transition: var(--transition);
                width: 90%;
            }
        }
        .vision__details:hover {
            opacity: 0.86;

            p {
                opacity: 1;
            }
        }

        .vision__title {
            width: 85%;
            transform: translateY(-60%);
            border-radius: 4px;
            padding-top: 9px;
            padding-bottom: 9px;
            background: #FFFFFF;
            box-shadow: 0px 1px 19px rgba(0, 0, 0, 0.1);
            margin-left: auto;
            margin-right: auto;
            p {
                color: #000;
            }
        }
    }
}

#vision {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5%;
    margin-bottom: 5rem;
    width: 100%;

    // TODO fix for all screens
    // for desktop this value should be (--container-width-lg) - 100 / 2
    // for tab this value should be (--container-width-md) - 100 / 2
    // for mob this value should be (--container-width-sm) - 100 / 2
    padding-left: 7.5%;
    

    // TABLET
    @media (max-width: variables.$md) { 
        padding-left: 5%;
    }

    // MOBILE
    @media (max-width: variables.$sm) { 
        padding-left: 5%;
        padding-right: 5%;
        flex-direction: column;
        transform: translateY(-8%);
        gap: 4rem;
        margin-bottom: 2rem;
    }

    .vision__img {
        width: 35%;

        // MOBILE
        @media (max-width: variables.$sm) { 
            width: 100%;
        }
    }

    .visiondetails__img {
        margin-left: auto;
        width: 52%;

        // MOBILE
        @media (max-width: variables.$sm) { 
            width: 100%;
        }
    }
}