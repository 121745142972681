@use '../../utils/variables' as variables;

#coming-soon {
    background-image: url('../../assets/coming_soon_background.svg');
    object-fit: cover;
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: var(--color-secondary-dark);

    .comingsoon__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;

        .logo__img {
            width: 15%;
            min-width: 15%;

            // TABLET
            @media (max-width: variables.$md) { 
                width: 20%;
                min-width: 20%;
            }

            // MOBILE
            @media (max-width: variables.$sm) { 
                width: 50%;
                min-width: 50%;
            }
        }

        .comingsoon__img {
            width: 30%;
            min-width: 30%;

            // TABLET
            @media (max-width: variables.$md) { 
                width: 40%;
                min-width: 40%;
            }

            // MOBILE
            @media (max-width: variables.$sm) { 
                width: 100%;
                min-width: 100%;
            }
        }

        p {
            width: 45%;
            
            // TABLET
            @media (max-width: variables.$md) { 
                width: 50%;
            }

            // MOBILE
            @media (max-width: variables.$sm) { 
                width: 100%;
            }
        }

        button {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 139.3%;
            /* or 25px */

            text-align: center;
            letter-spacing: 0.06em;
            padding: 10px 50px;
            text-transform: uppercase;

            // TABLET
            @media (max-width: variables.$md) { 
                font-size: 9.3375px;
                line-height: 139.3%;
            }

            // MOBILE
            @media (max-width: variables.$sm) { 
                font-size: 13.7368px;
                line-height: 139.3%;
            }
        }
    }
}