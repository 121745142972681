@use '../../utils/variables' as variables;

#nav {

    .nav__container {
        padding-top: 10px;
        padding-bottom: 10px;
        position: fixed;
        z-index: 100;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 0.533316px 10.133px rgba(0, 0, 0, 0.1);

        // TABLET
        @media (max-width: variables.$md) { 
            // padding-top: 12px;
            // padding-bottom: 12px;
        }

        // MOBILE
        @media (max-width: variables.$sm) { 
            padding-top: 6px;
            padding-bottom: 3px;
        }

        .nav__flexcontainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 2rem;
        }

        a {
            img {
                height: 45px;
    
                @media (max-width: 1200px) { 
                    height: 30px;
                }
            }
        }

        ul {
            display: flex;
            gap: 2.5vw;

            @media (max-width: 1300px) { 
                gap: 2vw;
            }

            // TABLET
            @media (max-width: variables.$md) { 
                gap: 1.3vw;
            }

            a {
                font-weight: 400;
                font-size: 15px;
                line-height: 28px;
                color: #000;
                cursor: pointer;

                // restrict text to just one line
                text-overflow: ellipsis; 
                white-space: nowrap;

                @media (max-width: 1200px) { 
                    font-size: 12px;
                    line-height: 20px;
                }

                // TABLET
                @media (max-width: variables.$md) { 
                    font-size: 10px;
                    line-height: 15px;
                }

                // MOBILE
                @media (max-width: variables.$sm) { 
                    font-size: 24px;
                    line-height: 43px;
                }
            }

            a:hover {
                color: var(--color-primary);
            }
        }

        button {
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            padding: 7px;
            color: white;
            border-radius: 3px;
            width: fit-content;

            // restrict text to just one line
            text-overflow: ellipsis; 
            white-space: nowrap;

            @media (max-width: 1200px) { 
                // width: 80px;
                line-height: 20px;
            }

            // MOBILE
            @media (max-width: variables.$sm) { 
                // width: fit-content;
            }
        }

        .mob__nav {
            position: absolute;
            z-index: 100;
            top: 100%;  
            left: 0;  
            width: 100%;
            height: 130vh;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            transition:margin 300ms linear;
            background: linear-gradient(180deg, #03577B 0%, #1D3463 100%);
            text-align: left;
            padding-left: 2rem;
            padding-top: 3rem;

            a {
                font-weight: 400;
                font-size: 24px;
                color: white;
                transition: var(--transition);
            }
      
      
            // li {
            //   background-color: var(--color-secondary);
            //   font-size: 18px;
            //   line-height: 21px;
            //   padding: 24px;
            //   text-align: left;
            //   border-bottom: 0.2px solid rgba(255, 255, 255, 0.5);
            //   color: white;
            //   -webkit-transition: all 0.7s ease-out;
            //   -moz-transition: all 0.7s ease-out;
            //       -ms-transition: all 0.7s ease-out;
            //       -o-transition: all 0.7s ease-out;
            //           transition: all 0.7s ease-out;
      
            //   a {
            //       color: white;
            //       transition: var(--transition);
            //   }
            // }
        }

        .show__mobnav {
            margin-left: 0;
        }
    
        .hide__mobnav { 
            margin-left: 102%;
        }
    }


}