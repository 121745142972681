@use '../../utils/variables' as variables;

#stza {
    background-color: var(--color-secondary);
    padding-top: 5rem;
    padding-bottom: 5rem;

    .stza__description {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1.3rem;
        margin-bottom: 1.6rem;

        // TABLET
        @media (max-width: variables.$md) { 

        }

        // MOBILE
        @media (max-width: variables.$sm) { 
            width: 100%;
        }
    }

    .stza__points {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 5%;

        // MOBILE
        @media (max-width: variables.$sm) { 
            grid-template-columns: 1fr;
            gap: 2.5rem;
        }

        .stza__singlepoint {
            p {
                // MOBILE
                @media (max-width: variables.$sm) { 
                    width: 50%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}