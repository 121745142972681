@use '../../utils/variables' as variables;

#brochure {

    padding-top: 2rem;
    padding-bottom: 2rem;

    .custom__toolbar {
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        margin-bottom: 1rem;

        .title {
            font-family: 'Baloo Paaji 2';
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 139.3%;
            /* or 29px */

            text-align: center;
            letter-spacing: 0.06em;
            text-transform: uppercase;

            color: #3E3E3E;
        }

        .middle__section {
            color: var(--color-gray-80);
            font-size: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;

            .zoom__icon {
                background-color: var(--color-gray-f9);
                padding: 10px 15px;
                border-radius: 16px;
                display: flex;
                justify-content: center;
                cursor: pointer;
                transition: var(--transition);
            }

            .zoom__icon:hover {
                background-color: var(--color-gray-e4);
            }

            .page__number {
                font-family: 'Baloo Paaji 2';
                font-style: normal;
                font-weight: 600;
                font-size: 14.1064px;
                line-height: 139.3%;
                /* identical to box height, or 20px */

                text-align: center;
                letter-spacing: 0.06em;
                text-transform: uppercase;
                color: var(--color-gray-80);

                .current__page {
                    color: var(--color-gray-4e);
                }
            }
        }

        .download__btn {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            padding: 7px 20px;
            
            font-family: 'Baloo Paaji 2';
            font-style: normal;
            font-weight: 600;
            font-size: 16.8467px;
            line-height: 139.3%;
            /* or 23px */

            text-align: center;
            letter-spacing: 0.06em;
            text-transform: uppercase;
        }
    }

    .brochure-frame {
        height: 80vh;
        width: 100%;

        // MOBILE
        @media (max-width: variables.$sm) { 
            height: 50vh;
        }
    }

    .pdf__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0.5rem;
        margin-bottom: 5rem;
        gap: 0.5rem;

        .next_prev__buttons {
            display: flex;
            flex-direction: row;
            gap: 1.5rem;
            .previous__btn {
                padding: 7px 11px;
                background-color: #fff;
                color: var(--color-primary);
                border: 1px solid var(--color-primary);
                display: flex;
            }
    
            .next__btn {
                padding: 7px 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff !important;
                font-family: 'Baloo Paaji 2';
                font-style: normal;
                font-weight: 600;
                font-size: 16.0472px;
                line-height: 139.3%;
                /* or 22px */
    
                text-align: center;
                letter-spacing: 0.06em;
                text-transform: uppercase;
                span {
                    margin-left: 10px;
                    display: flex;
                }
            }
        }
    }


}